.mapboxgl-ctrl-top-right {
  margin-top: 15vh;
}

.marker-icon {
  background-position: center;
  background-size: 40px 40px;
  border-radius: 10%; /* added border radius */
  height: 40px; /* changed to match width */
  width: 40px;
  left: 4px;
  position: absolute;
  text-align: center;
  top: 3px;
}
.marker {
  height: 30px;
  width: 30px;
}
.marker-content {
  background: #c30b82;
  border-radius: 10% 10% 10% 10%;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
  /* transform: rotate(-45deg); */
  width: 30px;
}
.marker-content::before {
  background: #ffffff;
  border-radius: 5%;
  content: "";
  height: 24px;
  margin: 3px 0 0 3px;
  position: absolute;
  width: 24px;
}

.marker-pointer {
  position: absolute;
  left: 50%; /* center it horizontally */
  transform: translateX(-50%);
  bottom: -10px; /* place it below the marker-content */
  border-style: solid;
  border-width: 10px 10px 0 10px; /* adjust the border width to form a downward-pointing triangle */
  border-color: #c31a26 transparent transparent transparent; /* keep the original red color */
  height: 0;
  width: 0;
}

.marker-circle-icon {
  background-position: center;
  background-size: 22px 22px;
  border-radius: 50%;
  height: 22px;
  left: 4px;
  position: absolute;
  text-align: center;
  top: 3px;
  transform: rotate(45deg);
  width: 22px;
  z-index: 20;
}
.marker-circle {
  height: 30px;
  width: 30px;
}
.marker-circle-content {
  background: #c30b82;
  border-radius: 50% 50% 50% 0;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  width: 30px;
}
.marker-circle-content::before {
  background: #ffffff;
  border-radius: 50%;
  content: "";
  height: 24px;
  margin: 3px 0 0 3px;
  position: absolute;
  width: 24px;
}

.marker-label {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  transform: translate(-50%, 48%);
  display: none; /* Hidden by default */
}

.station-label {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 48%);
  color: rgba(0, 0, 0, 0.3); /* Primary transparent color */
  color: #000000; /* Fallback color if rgba fails */
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 300; /* Lighter bold for "slim bold" */
  letter-spacing: -0.8px; /* Further slimming effect */
  border-radius: 4px;
  white-space: nowrap;
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Test background to see transparency */
  display: none; /* Hidden by default */
  border: #00000038 0.4px solid;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); /* Add shadow */
}

/* Optional: Show on hover for testing */

.mapboxgl-popup-anchor-bottom {
  z-index: 1000;
}

.mapboxgl-popup-anchor-top {
  z-index: 1000;
}

.mapboxgl-popup-anchor-left {
  z-index: 1000;
}

.mapboxgl-popup-anchor-right {
  z-index: 1000;
}

.mapboxgl-popup-anchor-top-left {
  z-index: 1000;
}

.mapboxgl-popup-anchor-top-right {
  z-index: 1000;
}

.mapboxgl-popup-anchor-bottom-left {
  z-index: 1000;
}

.mapboxgl-popup-anchor-bottom-right {
  z-index: 1000;
}

.mapboxgl-popup-anchor-center {
  z-index: 1000;
}

/* styling for POI popup */
.custom-popup .tt-popup-content {
  border: 2px solid black !important; /* Black border */
  border-radius: 10px !important; /* Rounded corners */
  box-shadow: 0px 0px 5px black !important; /* Dark outline */
  padding: 10px;
  background-color: white !important; /* Ensure visibility */
}

.custom-popup .tt-popup-tip {
  border-top-color: black !important; /* Makes the arrow tip black */
  border-width: 2px !important;
}
.mapboxgl-popup-content {
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  padding: 10px 10px 15px;
  pointer-events: auto;
  /* Add these properties to handle text wrapping and overflow */
  max-width: 300px; /* Set a reasonable max-width to constrain the popup */
  word-wrap: break-word; /* Break long words to fit within the container */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
  white-space: normal; /* Allow text to wrap naturally */
  overflow: hidden; /* Prevent content from spilling out */
}

/* Example styles for the marker icon */

