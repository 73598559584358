* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Poppins';
    font-weight: normal;
    font-style: normal;
}

body {
    height: auto;
    font-family: 'Poppins', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    line-height: 1.6;
}

.scrollable-container {
    height: auto;
    width: 100%;          /* Set a width */
             /* Set a height */
    overflow-y: auto;      /* Add vertical scrollbar if needed */
    border: 1px solid #ccc; /* Optional: add a border */
    padding: 10px;        /* Optional: add some padding */
}

.container {
    
    max-width: 1200px;
    margin: 20px auto;
    /* padding-top: 80px; */
    padding-bottom: 0%;
    background-color: white;
    
    border-radius: 20px;
}

.privacy-policy h3 {
    font-size: 2rem;
    margin-bottom: 10px;
    padding-left: 70px;
    color: #333;    
}
.date {
    padding-left: 70px;
    color: #999;
    font-size: 1rem;
    margin-bottom: 30px;
}

.content-section {
    height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    padding-bottom: 0px;
    word-wrap: normal;
    
}

.grid-container {
    height: auto;
    margin-bottom: auto;
    display: grid;
    grid-template-columns: 150px 1fr; /* Three equal columns */
    grid-template-rows: auto auto; /* Auto-height rows */
    gap: 10; /* Space between grid items */
    padding-bottom: auto;
    padding-left: 0px;
    padding-right: 0px;

    
}

.grid-item1 {
    height: auto;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 00px;
    padding-top: 0px;
    grid-column: span 1; /* Top, Right, Bottom, Left */
    text-align: left;
    font-size: 20px;
    margin-bottom: 0px;
    margin-right: px;
    margin-left: 20px;
    
}
/* .vertical-line-container {
    display: flex;
    flex-grow: 1;
    height: auto;
    flex-direction: column; 
    align-items: center;    
    position: relative;
    padding-bottom: auto;    
    margin-bottom: auto;
    padding-right: 10px;
   
} */

.grid-item2 {  
    height: auto;
    padding-left: 0px;
    padding-right: 50px;
    padding-bottom: auto;
    padding-top: 5px;
    text-align: left; 
    margin-bottom: auto;
    margin: left 30px ;
    margin-right: 0px;    

}
.grid-item2 p{
    font-size: 56;  

}
strong{
    font-size: 0.9rem;  
}

h2 {
    font-size: 1.8rem,auto;
    margin-bottom: 10px;
    color: #333;
    
}

.box {
    height: auto;
    flex: 1;
    gap: 0px;
    justify-content: center;  /* Center horizontally */
    align-items: center;   
    text-align: left;
    margin-bottom: 10px;
    
}

.box p{
    font-size: 0.9rem; 
    justify-content: center;  /* Center horizontally */
    align-items: center;    
    padding-bottom: 10px;
    margin-bottom:auto ;
}
.box h3{
    font-size: 0.8rem; 
    padding-bottom: 10px;
    margin-bottom:auto;

}






.grid-item4 {
    height: auto;
    gap: 80px 100px;
    grid-column: span 1;
    padding-left: 120px;
    padding-right: 5px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: right;
    align-items: right;
    font-size: 20px;

}   

footer{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    background-color: #0091B7;
}

.vertical-line-container {
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center;    /* Center items horizontally */
    position: relative;
    padding-right: 10px;
    height: 300px;
}

.circle1, .circle2, .circle3, .circle4, .circle5, .circle6, .circle7, .circle8 {
    width: 40px;
    height: 40px;
    background-color: #4CAF50; /* Default background color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black; /* Text color */
    font-size: 20px; /* Font size for the number */
    font-weight: bold;
    margin-bottom: 0px; /* Add some space between the circle and the dashed line */
}

.circle2 {
    background-color: #FF8B5C80;
}

.circle3 {
    background-color: #5F95FF80;
}

.circle4 {
    background-color: #99D14C80;
}

.circle5 {
    background-color: #F9B82180;
}

.circle6 {
    background-color: #FB665D80;
}

.circle7 {
    background-color: #0E5D7680;
}

.circle8 {
    background-color: #5F95FF80;
}

.vertical-dash1, .vertical-dash2, .vertical-dash3, .vertical-dash4, .vertical-dash5, .vertical-dash6, .vertical-dash7 {
        line-height-step: auto;
        width: 5px;           
        border-left: 3.5px dashed #4CAF50; /* Dashed line with color */
        flex-grow: 1; /* This will allow the dashed line to stretch and fill the space */
}

.vertical-dash2 {
    border-left-color: #FF8B5C80;
}

.vertical-dash3 {
    border-left-color: #5F95FF80;
}

.vertical-dash4 {
    border-left-color: #99D14C80;
}

.vertical-dash5 {
    border-left-color: #F9B82180;
}

.vertical-dash6 {
    border-left-color: #FB665D80;
}

.vertical-dash7 {
    border-left-color: #0E5D7680;
}


