img {
  max-width: 100%;
  height: auto;
}

.slick-prev:before {
  color: #3a3a3a !important;
}
.slick-next:before {
  color: #3a3a3a !important;
}
.slick-track {
  display: flex;
  align-items: center;
}
/* index -1  */
.slide-icon {
  /* border-width: 1px; */
  /* border-color: rgb(255, 196, 205); */
  /* border-style: solid; */
  /* border-radius: 5%;
  background-color: #eeeaea;
  text-align: center; */
  display: block;
  padding: 10px;
  box-sizing: border-box;
  margin: 0px 0.5vw;
}

.slide-icon span {
  font-family: "Inter", monospace;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
  overflow: hidden;
  min-height: auto; /* Adjust this based on the tallest text label */
  line-height: 16px; /* Ensures vertical alignment */
}

.slide-icon img {
  display: block;
  margin: 0 auto;
  padding-bottom: 5px;
  padding-top: 12px;
  width: 50px;
}

.highlight .slide-icon {
  background: #dedede; /* Add the highlight background for selected */
  border-radius: 5%;
  margin-bottom: 8px;
  color: #000;
  transition: background 0.3s ease; /* Smooth transition for color change */
}

.highlight .slide-icon img {
  filter: brightness(110%); /* Highlighted image */
  transition: filter 0.3s ease;
}
.single-nav.unavailable {
  pointer-events: none;
  cursor: not-allowed;
}

.grayscale {
  filter: grayscale(100%);
}

.blur {
  filter: blur(1px); /* Adjust the pixel value as needed */
}

/* If you want both effects combined for unavailable items */
.grayscale.blur {
  filter: grayscale(100%) blur(1px);
}
.unavailable-text {
  /* font-size: 4px; Adjust this value as needed */
  /* Optional: you can add more styling if desired */
  opacity: 0.6;
  /* color: #666; */
}